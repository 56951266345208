import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { getLoginData } from "../services/authSvc";
import reportSvc from "../services/reportSvc";
import SpinnerLoading from "../components/loading/SpinnerLoading";
import { formatToRupiah } from "../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
moment.tz.setDefault("Asia/Bangkok");

const DetailReport = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = getLoginData();
  const role = user.role;

  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState({});

  useEffect(() => {
    const fetchDetailReport = async () => {
      setIsLoading(true);
      try {
        const resp = await reportSvc.getReportById(id);
        // setTimeout(() => {
        if (resp.status === 200) {
          setReport(resp.data.data);
        }
        // }, 5000);
      } catch (error) {
        Swal.fire("Error", "Gagal mengambil data laporan", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetailReport();
  }, [id]);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Konfirmasi Penghapusan",
      text: "Apakah Anda yakin ingin menghapus laporan ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      try {
        setIsLoading(true);
        const res = await reportSvc.deleteReportById(id);
        if (res.status === 200) {
          Swal.fire("Dihapus!", "Laporan berhasil dihapus.", "success").then(
            () => history.goBack()
          );
        }
      } catch (error) {
        Swal.fire("Error", "Gagal menghapus laporan", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImageError = (type, index) => {
    if (type === "temuan") {
      setReport((prevReport) => {
        return {
          ...prevReport,
          temuan: {
            ...prevReport.temuan,
            [index]: "error",
          },
        };
      });
    } else if (type === "dokumentasi") {
      setReport((prevReport) => ({
        ...prevReport,
        dokumentasis: prevReport.dokumentasis.map((dokumentasi, i) => {
          if (i === index) {
            return {
              ...dokumentasi,
              dokumentasi_url: "error",
            };
          }
          return dokumentasi;
        }),
      }));
    }
  };

  const descriptionItems = [
    {
      label: "Nama ATM",
      value: report.asset_name ? (
        <Button
          type="link"
          onClick={() => {
            history.push(`/asset/detail/${report.id_asset}`);
          }}
          style={{ padding: 0, color: "#f76617" }}
        >
          {report.asset_name}
        </Button>
      ) : null,
    },

    { label: "Kode ATM", value: report.asset_kode },
    { label: "Kunjungan", value: report.kunjungan },
    {
      label: "Kantor Cabang",
      value: report.id_pelanggan ? (
        <Button
          type="link"
          onClick={() => {
            history.push(`/customer/detail/${report.id_pelanggan}`);
          }}
          style={{ padding: 0, color: "#f76617" }}
        >
          {report.pelanggan_name}
        </Button>
      ) : null,
    },
    { label: "Alamat", value: report.asset_alamat },
    {
      label: "Operator",
      value: report.user_name ? (
        <Button
          type="link"
          onClick={() => {
            history.push(`/user/detail/${report.id_user}`);
          }}
          style={{ padding: 0, color: "#f76617" }}
        >
          {report.user_name}
        </Button>
      ) : null,
    },
    {
      label: "Waktu Pengerjaan",
      value: report.date_done
        ? moment.utc(report.date_done).format("YYYY-MM-DD HH:mm:ss")
        : null,
    },
  ];

  return (
    <div className="align-items-center py-4 w-100">
      <Card
        title={<span style={{ fontSize: "20px" }}>Detail Laporan</span>}
        extra={
          <div className="d-flex">
            {role === "admin" && (
              <Tooltip title={"Tambah ATM"}>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    handleDelete(id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ fontSize: "14px", paddingRight: "10px" }}
                  />
                  Hapus
                </Button>
              </Tooltip>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        <Descriptions bordered column={1} size="small">
          {descriptionItems.map((item, index) => (
            <Descriptions.Item key={index} label={item.label}>
              {item.value || <Skeleton.Input active />}
            </Descriptions.Item>
          ))}
        </Descriptions>

        <Descriptions title="Dokumentasi" size="small" className="mb-4 mt-4">
          {isLoading ? (
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Skeleton active />
              </Col>
            </Row>
          ) : (
            <div>
              <Row gutter={[20, 20]}>
                {report.dokumentasis && report.dokumentasis.length > 0 ? (
                  report.dokumentasis.map((image, index) => (
                    <Col key={image.id} xs={12} md={6} lg={3}>
                      <div className="d-flex flex-column">
                        {image.dokumentasi_url ? (
                          <Image
                            src={image.dokumentasi_url}
                            alt={`Image ${index + 1}`}
                            className="mr-2 mb-2 rounded"
                            onError={() =>
                              handleImageError("dokumentasi", image.id)
                            }
                          />
                        ) : (
                          <Skeleton.Image />
                        )}
                        <span>
                          {`${index + 1}.`}{" "}
                          {image.nama_jenis_dokumentasi_snapshot}
                        </span>
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <p>Tidak ada dokumentasi yang tersedia.</p>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Descriptions>

        <Descriptions title="Temuan" size="small" className="mb-4">
          <Row>
            <Col span={24}>
              <div>
                {isLoading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <span className="fw-bold mr-4">Keterangan:</span>{" "}
                      <span className="mb-0 ms-1">
                        {report.temuan
                          ? report.temuan.keterangan
                          : "Tidak ada temuan..."}
                      </span>
                    </div>
                    <Row gutter={[20, 20]}>
                      {report.temuan?.temuan1_url && (
                        <Col xs={12} md={3}>
                          <div className="mb-4 d-flex flex-column">
                            <span className="mb-1">1. Temuan 1</span>
                            {report.temuan.temuan1_url !== "error" ? (
                              <Image
                                src={report.temuan.temuan1_url}
                                alt="Temuan 1"
                                className="mr-2 mb-2 rounded"
                                onError={() =>
                                  handleImageError("temuan", "temuan1_url")
                                }
                              />
                            ) : (
                              <Skeleton.Image />
                            )}
                          </div>
                        </Col>
                      )}
                      {report.temuan?.temuan2_url && (
                        <Col xs={12} md={3}>
                          <div className="mb-4 d-flex flex-column">
                            <span className="mb-1">2. Temuan 2</span>
                            {report.temuan.temuan2_url !== "error" ? (
                              <Image
                                src={report.temuan.temuan2_url}
                                alt="Temuan 2"
                                className="mr-2 mb-2 rounded"
                                onError={() =>
                                  handleImageError("temuan", "temuan2_url")
                                }
                              />
                            ) : (
                              <Skeleton.Image />
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Descriptions>
      </Card>
    </div>
  );
};

export default DetailReport;
