import { useEffect, useRef, useState } from "react";
import {
  faArrowDown,
  faClose,
  faFileExport,
  faPlus,
  faPrint,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
  Dropdown,
  Image,
  Menu,
  Table,
  Tooltip,
  message,
  Card as CardAntd,
} from "antd";
import { Button, Input, Space } from "antd";
import dayjs from "dayjs";
import {
  DownOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { DatePicker, Select } from "antd";

import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { getLoginData } from "../../services/authSvc";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";

import reportTransactionSvc from "../../services/reportTransactionSvc";
import customerSvc from "../../services/customerSvc";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputGroup,
  Spinner,
  Form,
  Card,
} from "@themesberg/react-bootstrap";
import { customStyles } from "../../utils/SelectUtils";
import axios from "axios";
import { formatToRupiah } from "../../utils/Helper";
import utilSvc from "../../services/utilSvc";
import { ColumnTransaction } from "../../template/TemplateColumn";

moment.tz.setDefault("Asia/Bangkok");
const { RangePicker } = DatePicker;

const Transactions = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const searchInputRef = useRef(null);
  const [reportTransaction, setReportTransaction] = useState([]);
  const [reportTransactionPrint, setReportTransactionPrint] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const history = useHistory();

  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handleChangeDate = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    }
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Konfirmasi Hapus",
      text: "Tindakan ini akan menghapus permanen data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batalkan",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data transaksi...");
        const res = await reportTransactionSvc.deleteReportTransaction(id);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(() => {
            fetchReportTransaction();
          });
        } else {
          throw new Error("Gagal menghapus");
        }
      } catch (error) {
        let message = error.response?.data?.message || "Gagal menghapus data ";
        showErrorAlert("Gagal", message);
      }
    }
  };

  // const columns = [
  //   {
  //     title: "Nama Kegiatan",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (text) => <span>{text}</span>,
  //   },
  //   {
  //     title: "Harga",
  //     dataIndex: "price",
  //     key: "price",
  //     render: (text) => <span>{formatToRupiah(text)}</span>,
  //   },
  //   {
  //     title: "Deskripsi",
  //     dataIndex: "description",
  //     key: "description",
  //     render: (text) => <span>{text}</span>,
  //   },
  //   {
  //     title: "ATM",
  //     dataIndex: "asset",
  //     key: "asset",
  //     render: (text) => <span>{text?.name}</span>,
  //   },
  //   {
  //     title: "Cabang",
  //     dataIndex: "pelanggan",
  //     key: "pelanggan",
  //     render: (text) => <span>{text?.name}</span>,
  //   },
  //   {
  //     title: "Foto Sebelum",
  //     key: "beforeImages",
  //     render: (text, record) => {
  //       const beforeImages = record.report_transaction_images.filter(
  //         (img) => img.image_type === "before"
  //       );

  //       return (
  //         <div>
  //           {beforeImages.length > 0 ? (
  //             beforeImages.map((img, index) => (
  //               <Image
  //                 key={index}
  //                 width={60}
  //                 src={img.image_url}
  //                 style={{ paddingRight: "2px" }}
  //                 fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
  //               />
  //             ))
  //           ) : (
  //             <span>-</span>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Foto Sesudah",
  //     key: "afterImages",
  //     render: (text, record) => {
  //       const afterImages = record.report_transaction_images.filter(
  //         (img) => img.image_type === "after"
  //       );

  //       return (
  //         <div>
  //           {afterImages.length > 0 ? (
  //             afterImages.map((img, index) => (
  //               <Image
  //                 key={index}
  //                 width={60}
  //                 src={img.image_url}
  //                 style={{ paddingRight: "2px" }}
  //                 fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
  //               />
  //             ))
  //           ) : (
  //             <span>-</span>
  //           )}
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     title: "Tanggal Dibuat",
  //     key: "createdAt",
  //     render: (text, record) => {
  //       const date = record.createdAt
  //         ? moment(record.createdAt).format("DD MMMM YYYY HH:mm:ss")
  //         : "-";

  //       return <div>{date}</div>;
  //     },
  //   },

  //   {
  //     title: "Tanggal Pengerjaan",
  //     key: "operation_date",
  //     render: (text, record) => {
  //       const date = record.operation_date
  //         ? moment(record.operation_date).format("DD MMMM YYYY HH:mm:ss")
  //         : "-";

  //       return <div>{date}</div>;
  //     },
  //   },
  //   {
  //     title: "Aksi",
  //     key: "actions",
  //     fixed: "right",
  //     render: (text, record) => {
  //       const items = [
  //         {
  //           key: "0",
  //           label: "Lihat",
  //           onClick: () => {
  //             history.push(
  //               `/report/transaction/view/${record.id_report_transaction}`
  //             );
  //           },
  //         },
  //         {
  //           key: "1",
  //           label: "Edit",
  //           onClick: () => handleEdit(record.id_report_transaction),
  //         },
  //         {
  //           key: "2",
  //           label: "Delete",
  //           danger: true,
  //           onClick: () => handleDelete(record.id_report_transaction),
  //         },
  //       ];

  //       return (
  //         <Space size="middle">
  //           <Dropdown overlay={<Menu items={items} />}>
  //             <Button icon={<EllipsisOutlined />} />
  //           </Dropdown>
  //         </Space>
  //       );
  //     },
  //   },
  // ];

  const columnsPrint = [
    {
      title: "No",
      key: "no",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Nama Kegiatan",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{formatToRupiah(text)}</span>,
    },
    {
      title: "ATM",
      dataIndex: "asset",
      key: "asset",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Cabang",
      dataIndex: "pelanggan",
      key: "pelanggan",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      render: (description) => <span>{description}</span>,
    },
    {
      title: "Foto Sebelum",
      key: "beforeImages",
      render: (text, record) => {
        const beforeImages = record.report_transaction_images.filter(
          (img) => img.image_type === "before"
        );

        return (
          <div>
            {beforeImages.length > 0 ? (
              beforeImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Foto Sesudah",
      key: "afterImages",
      render: (text, record) => {
        const afterImages = record.report_transaction_images.filter(
          (img) => img.image_type === "after"
        );

        return (
          <div>
            {afterImages.length > 0 ? (
              afterImages.map((img, index) => (
                <Image
                  key={index}
                  width={60}
                  src={img.image_url}
                  style={{ paddingRight: "2px" }}
                  fallback="https://storage.googleapis.com/bni-gme/transaksi/2024-08/2024-08-12/3b072d70-58d1-11ef-a445-8b8613c4cc11.png"
                />
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },

    {
      title: "Tanggal Dibuat",
      key: "createdAt",
      render: (text, record) => {
        const date = record.createdAt
          ? moment(record.createdAt).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },
    {
      title: "Tanggal Pengerjaan",
      key: "operation_date",
      render: (text, record) => {
        const date = record.operation_date
          ? moment(record.operation_date).format("DD MMMM YYYY HH:mm:ss")
          : "-";

        return <div>{date}</div>;
      },
    },
  ];

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  const fetchReportTransaction = async () => {
    setIsLoading(true);
    const resp = await reportTransactionSvc.getReportTransactions({
      params: {
        page: page,
        limit: pageSize,
        query: searchTerm ? searchTerm : null,
        id_pelanggan: selectedCustomer ? selectedCustomer : null,
        start_date: startDate,
        end_date: endDate,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setReportTransaction(resp.data.data);
      setTotalPage(resp.data.totalPage);
      setTotalRows(resp.data.totalRows);
    }

    setIsLoading(false);
  };

  const fetchReportTransactionPrint = async () => {
    const resp = await reportTransactionSvc.getReportTransactions({
      params: {
        page: 0,
        limit: 10000,
        query: searchTerm ? searchTerm : null,
        id_pelanggan: selectedCustomer ? selectedCustomer.value : null,
        start_date: startDate,
        end_date: endDate,
        fields: undefined,
        includes: JSON.stringify([
          {
            model: "pelanggan",
            attributes: ["name"],
          },
        ]),
      },
    });
    if (resp.status === 200) {
      setReportTransactionPrint(resp.data.data);
    }
  };

  const fetchCustomers = async () => {
    if (user?.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchReportTransaction();
  }, [page, startDate, endDate, pageSize, selectedCustomer]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchReportTransaction();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleEkspor = async () => {
    message.open({
      key: "loadingexcel",
      type: "loading",
      content: "Start generating excel, Please wait...",
      duration: 0,
    });
    setDataLoaded(false);
    await fetchReportTransactionPrint();
  };

  useEffect(() => {
    if (reportTransactionPrint.length > 0) {
      setDataLoaded(true);
    }
  }, [reportTransactionPrint]);

  useEffect(() => {
    if (dataLoaded) {
      exportToExcel();
    }
  }, [dataLoaded]);
  const BEFORE_IMAGES_COLUMN_INDEX = 6;
  const AFTER_IMAGES_COLUMN_INDEX = 7;

  const exportToExcel = async () => {
    const table = document.getElementById("table-laporan");

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const beforeImageUrls = [];
    const afterImageUrls = [];

    table.querySelectorAll("tr").forEach((row, rowIndex) => {
      const rowData = [];
      row.querySelectorAll("td, th").forEach((cell, cellIndex) => {
        let cellText = cell.textContent ? cell.textContent.trim() : "";
        if (cellText.includes("Preview")) cellText = "";
        rowData.push(cellText);

        if (cellIndex === BEFORE_IMAGES_COLUMN_INDEX) {
          const imgElement = cell.querySelector("img");
          if (imgElement) {
            beforeImageUrls.push({ src: imgElement.src, row: rowIndex + 1 });
          }
        } else if (cellIndex === AFTER_IMAGES_COLUMN_INDEX) {
          const imgElement = cell.querySelector("img");
          if (imgElement) {
            afterImageUrls.push({ src: imgElement.src, row: rowIndex + 1 });
          }
        }
      });
      worksheet.addRow(rowData);
    });

    await addImagesToWorksheet(
      workbook,
      worksheet,
      beforeImageUrls,
      BEFORE_IMAGES_COLUMN_INDEX
    );
    await addImagesToWorksheet(
      workbook,
      worksheet,
      afterImageUrls,
      AFTER_IMAGES_COLUMN_INDEX
    );

    worksheet.columns.forEach((column, index) => {
      if (index === 0) {
        column.width = 5;
      } else if (index === 6 || index === 7) {
        column.width = 15;
      } else {
        column.width = 25;
      }
      column.alignment = { horizontal: "center", vertical: "middle" };
    });
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.height = 50;
        row.alignment = { horizontal: "center", vertical: "middle" };
      }
    });

    message.destroy("loadingexcel");
    message.open({
      type: "success",
      content: "Excel downloaded!",
      duration: 2,
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `list_transaksi_exported.xlsx`);
    });
  };

  const addImagesToWorksheet = async (
    workbook,
    worksheet,
    imageInfos,
    columnIndex
  ) => {
    for (const { src, row } of imageInfos) {
      if (src) {
        try {
          const response = await utilSvc.getImageBuffer(src);

          const imageBuffer = response.data;
          const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: "png",
          });

          const columnWidth = 15 * 7;
          const rowHeight = 50;

          const imageWidth = 60;
          const imageHeight = 60;

          const leftOffset = (columnWidth - imageWidth) / 2;
          const topOffset = (rowHeight - imageHeight) / 2;

          worksheet.addImage(imageId, {
            tl: { col: columnIndex, row: row - 1 },
            ext: { width: imageWidth, height: imageHeight },
            editAs: "onecell",
            pos: {
              x: leftOffset,
              y: topOffset,
            },
          });
        } catch (error) {
          console.error("Failed to load image:", error);
        }
      }
    }
  };

  const handleAdd = () => {
    history.push("/report/transaction/add");
  };

  const handleEdit = async (id) => {
    history.push(`/report/transaction/edit/${id}`);
  };

  const columns = ColumnTransaction(history, role, handleDelete, handleEdit);

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedCustomer(null);
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title="Transaksi Pekerjaan ( Non Rutin )"
        extra={
          <div className="d-flex">
            {role === "admin" && (
              <div className="d-flex gap-2 ">
                <Tooltip title={"Tambah Transaksi"}>
                  <Button
                    variant="success"
                    size="sm"
                    // style={{ width: "100px" }}
                    onClick={handleAdd}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      // className="me-2"
                      style={{ fontSize: "14px" }}
                    />
                  </Button>
                </Tooltip>

                <Tooltip title={"Print Transaksi"}>
                  <Button
                    variant="success"
                    size="sm"
                    className=""
                    // style={{ width: "100px" }}
                    onClick={handleEkspor}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      // className="me-2"
                      style={{ fontSize: "14px" }}
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Nama kegiatan, deskripsi"
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>

          {user?.role == "admin" && (
            <InputGroup style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Pilih Kantor Cabang"
                allowClear
                showSearch
                onChange={handleSelectCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {customers.map((data) => (
                  <Select.Option
                    key={data.id_pelanggan}
                    value={data.id_pelanggan}
                  >
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </InputGroup>
          )}

          <InputGroup style={{ flexGrow: 1, flexBasis: "300px" }}>
            <RangePicker
              style={{ width: "100%" }}
              size="middle"
              onChange={handleChangeDate}
              allowClear={false}
              format="YYYY-MM-DD"
              value={[
                dayjs(startDate, "YYYY-MM-DD"),
                dayjs(endDate, "YYYY-MM-DD"),
              ]}
            />
          </InputGroup>

          <Button
            variant="secondary"
            size="sm"
            className="ml-2"
            style={{ height: "auto" }}
            onClick={(e) => {
              handleClearFilters();
            }}
          >
            Reset Filter
          </Button>
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            columns={columns}
            dataSource={reportTransaction}
            pagination={pagination}
            loading={isLoading}
            size="middle"
            // scroll={{ x: 1200, y: 600 }}
          ></Table>

          <Table
            columns={columnsPrint}
            dataSource={reportTransactionPrint}
            pagination={{ pageSize: reportTransactionPrint.length }}
            size="middle"
            id="table-laporan"
            style={{ display: "none" }}
          ></Table>
        </div>
      </CardAntd>
    </div>
  );
};

export default Transactions;
